<template>
  <v-app>
    <v-main>
      <v-container>
        <v-row align="center" justify="center" dense>
          <v-col cols="12" sm="8" md="4" lg="4">


            <v-card elevation="0">

              <v-card-title><span style="color:purple">Haz olvidado tu password?</span>
              <v-spacer></v-spacer> <v-icon color="red">error_outline</v-icon>
              </v-card-title>

              <v-card-text>
              <div class="text-center">
                <h1 class="mb-2"></h1>
                <p>Enviaremos un correo con tus nuevos datos para que ingreses nuevamente.</p>
              </div>

             
              <router-link :to="{name:'login'}">
                <v-img src="@/assets/profile_adobespark.png" alt="vuetify components logo" contain height="200" />
              </router-link>
             

                <v-text-field
                  v-model="email"
                  label="Ingresa aqui tu direccion de correo"
                  name="email"
                  prepend-inner-icon="email"
                  type="email"
                  class="rounded-0"
                  outlined
                  ></v-text-field>


                  <v-btn class="rounded-0" color="indigo" x-large block dark @click="enviarDatos()">Reset Password</v-btn>
               
              
              </v-card-text>

            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import axios from 'axios';
import { mapMutations } from "vuex";


export default {
name: 'login',
   props: {
      source: String,
   },
   data(){
      return{
           email:''
      }
   },
   
   methods:{

       ...mapMutations(['mostrarLoading','ocultarLoading']),


    enviarDatos(){
             
               this.mostrarLoading({titulo:'procesando'});
               let me=this;

                  axios.post('olvidado',
                             {'email':this.email}
                            ).then(response=>{

                                    console.log(response);
                                    alert(response.data.mensaje);
                                    me.email='';
                                    me.ocultarLoading();
                                    
                                    }).catch(e=>{
                                          console.log(e)
                                       
                                          me.ocultarLoading();
                                    });

          },


          }, 
          
          created(){

       }


          

}
</script>

<style lang="css" scoped>
</style>
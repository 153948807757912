<template>
<div class="container" mt-5>
  <v-container class="fill-height"  fluid mt-5 >
    <v-row align="center" justify="center">


    <v-col xs="10" sm="7" md="5"  lg="5"  xl="4"  class="align-center justify-center mt-5">
      <v-card class="auth-card" xs12 md6 lg6 xl6>
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="require('@/assets/profile_adobespark.png')"
              max-height="76px"
              max-width="66px"
              alt="logo"
              contain
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              Dashboard
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Bienvenido al Dashboard Gps ! 👋🏻
          </p>
          <p class="mb-2">
            Ingresa tus datos para iniciar sesion
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form   @submit.prevent="login">
            <v-text-field
              v-model="usuario.usr"
              outlined
              label="Usuario"
              placeholder=""
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="usuario.pwd"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder="············"
              append-icon="visibility"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox
                label="Recordar acceso"
                hide-details
                class="me-3 mt-1"
              >
              </v-checkbox>

              <!-- forgot link -->

               <router-link :to="{name:'olvidepassword'}">
                  Olvide mi password?
               </router-link>
              
            </div>

            <v-btn
              block
              color="primary"
              class="mt-6"
               type="submit"
            >
              Login
            </v-btn>
          </v-form>
           <div v-if="mensaje !=''"><p>{{mensaje}}</p></div>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            Nuevo en esta aplicacion?
          </span>
        
            <a href="mailto:monitoreo.sv@grupodisatel.com?subject=Solicitud creacion cuenta Dashboard Sherwin">Solicitar creacion de cuenta</a>
            
       
        </v-card-text>

        <!-- divider -->
        <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
      
          <v-divider></v-divider>
        </v-card-text>

        <!-- social links -->
        <v-card-actions class="d-flex justify-center">
          <v-btn
            v-for="link in socialLink"
            :key="link.icon"
            icon
            class="ms-1"
          >
            <v-icon :color="link.color">
              {{ link.icon }}
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>


      <v-col cols="12">
              <v-img
                height="150"
                style="z-index:2"
                :src="require(`@/assets/misc/mask-light.png`)"
                >

                <v-img
                class="mt-4 float-left"
                max-width="150"
                max-height="115"
                style="z-index:20"
                src="@/assets/misc/tree.png"
                ></v-img>



                 <v-img
                class="mt-2 float-right"
                max-width="150"
                max-height="115"
                style="z-index:20"
                src="@/assets/misc/tree-3.png"
                ></v-img>
                
                </v-img>

               

        </v-col>

      


  


     </v-row>

  
</v-container>
</div>
</template>


<script>

import axios from 'axios';
import { mapMutations } from "vuex";

export default {
name: 'login',
   props: {
      source: String,
   },
   data(){
      return{
           usuario:{usr:'', pwd:''},
           mensaje:'',

        socialLink : [
      {
        icon: 'engineering',
        color: '#4267b2',
       
      },
      {
        icon: 'hive',
        color: '#1da1f2',
      
      },
      {
        icon: 'phonelink',
        color: 'primary',
       
      },
      {
        icon: 'public',
        color: '#db4437',
       
      },
    ],

    isPasswordVisible:false,

      }
   },
   
   methods:{

       ...mapMutations(['mostrarLoading','ocultarLoading']),


    login(){
               console.log('clic:'+this.usuario);

               this.mostrarLoading({titulo:'accediendo'});
               let me=this;

                  axios.post('login', me.usuario)
                        .then(response=>{
                                    
                        
                                      console.log(response);

                                      const token=response.data.token;
                                      const dataUser=response.data.UsuarioDB;
                                      
                                      console.log(dataUser);

                                      if(dataUser && token){
                                         this.$store.dispatch("guardarToken", token);
                                      }

                                  

                                      me.ocultarLoading();
                                    

                                    }).catch(e=>{
                                          console.log(e)
                                          me.mensaje=e.response.data.mensaje;
                                          me.ocultarLoading();
                                    });

          },


          }, 
          
          created(){

          // login();

         this.$store.dispatch("autoLogin");

   }


          

}
</script>

